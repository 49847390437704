
import { defineComponent } from 'vue';
import MainTemplate from '../../atomic/templates/MainTemplate/MainTemplate.vue';
import FileUploader from '../../atomic/molecules/File-Uploader/File-Uploader.vue';

export default defineComponent({
  components: {
    'arc-main-template': MainTemplate,
    'arc-file-uploader': FileUploader,
  },
});
